import style from "./index.module.scss";
import React, { useState } from "react";
import Icon from "@Atom/Icon";
import book from "../../../assets/Images/book 1.png";
import imageModal from "../../../assets/Images/RoomGirlLearn.png";
import Modal from "@Atom/Modal";

export default function PreTestHeader({
  headerDecription = `Sebelum memulai pembelajaran di kelas ini silakan kerjakan
  Pre-Test terlebih dahulu untuk dapat memulai pembelajaran dari
  kelas yang sudah Anda beli. Silahkan baca ketentuan Pre-Test
  yang Anda kerjakan dibawah ini:`,
  textScore = "Skor Akhir Pre-Test",
  headerPreTest = "placeholder",
  onClickButtonStart = () => {},
  listDescription = [],
  type,
  modalTitle = "placeholder",
  modalDescription = "placeholder",
  textButton = "text button",
  isLockedQuiz,
  score,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    onClickButtonStart();
  };
  return (
    <div>
      <section className={style.container}>
        <div className={style.leftArea}>
          <div className={style.wrapperLeft}>
            <h1 className={style.header}>{headerPreTest}</h1>
            <div className={style.paragraph}>
              <p>{headerDecription}</p>
            </div>

            <div className={style.wrapperList}>
              {listDescription.map((item, idx) => (
                <div className={style.list} key={idx}>
                  <div>
                    <Icon
                      icon={"arrow_right"}
                      color={"white"}
                      size={24}
                      className={style.arrowRight}
                    />
                  </div>
                  <div>
                    <div className={style.descList}>{item}</div>
                  </div>
                </div>
              ))}
            </div>
            {(type === "quiz" && isLockedQuiz) ||
            (type === "postTest" && isLockedQuiz) ||
            (type === "preTest" && isLockedQuiz) ? (
              <div className={style.buttonQuizIsLocked}>
                <Icon icon={"lock"} size={"16"} color={"#C2C2C2"} />
                <button disabled={isLockedQuiz}> {textButton} </button>
              </div>
            ) : type === "quiz" && !!score ? (
              <div className={style.buttonQuiz}>
                <button
                  is-type={type === "quiz" ? "true" : "false"}
                  onClick={() => setIsOpen(true)}
                >
                  {textButton}
                </button>
              </div>
            ) : type === "preTest" && !!score ? (
              <button
                disabled={type === "preTest" && !!score}
                className={style.buttonIsFilled}
                onClick={() => setIsOpen(true)}
              >
                {textButton}
              </button>
            ) : (
              <button
                text={textButton}
                className={style.buttonNormal}
                onClick={() => setIsOpen(true)}
              >
                {textButton}
              </button>
            )}
            <Modal isOpen={isOpen}>
              <div className={style.modalContainer}>
                <div className={style.mainModal}>
                  <img
                    className={style.modalImage}
                    src={imageModal}
                    alt={imageModal}
                  />
                  <div className={style.description}>
                    <h1>{modalTitle} </h1>
                    <p dangerouslySetInnerHTML={{ __html: modalDescription }} />
                  </div>
                  <div className={style.buttonModal}>
                    <button onClick={() => setIsOpen(false)}>
                      <p className={style.textButton}>Kembali</p>
                    </button>
                    <button className={style.buttonStart} onClick={handleClose}>
                      <p className={style.textButton}>Mulai</p>
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className={style.rightArea}>
          {(type === "quiz" && isLockedQuiz) ||
          (type === "preTest" && isLockedQuiz) ||
          (type === "postTest" && isLockedQuiz) ? (
            <img src={book} alt={book} className={style.imageBook} />
          ) : (type === "preTest" && !!score) ||
            (type === "postTest" && !!score) ||
            (type === "quiz" && !!score) ? (
            <div className={style.wrapScoreResult}>
              <p>{textScore}</p>
              <div className={style.scoreText} score={score}></div>
            </div>
          ) : (
            <img src={book} alt={book} className={style.imageBook} />
          )}
        </div>
      </section>
    </div>
  );
}
