import SkillTestDetail from "@Organism/InstructorDashboard/SkilltestDetail";
import React, { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";


export default function SkillTestDetailPage() {
  const { studentAssesment } = useLoaderData();

  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={studentAssesment}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <SkillTestDetail data={value?.data} />}
      </Await>
    </Suspense>
  );
}
