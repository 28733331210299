import { useMemo, useState } from "react";
import MultipleChoice from "@Molecule/MultipleChoice";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import { shuffleArray } from "@Helpers/shuffleAray";
// import { shuffleArray } from "@Helpers/shuffleAray";

export default function QuizModal({ data, handleSubmit }) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [valueRadio, setValueRadio] = useState({});

  const handleOptionChange = (option, index) => {
    setSelectedOption((prev) => ({ ...prev, [index]: option }));
  };

  const handleNextCard = () => {
    setCurrentCardIndex(currentCardIndex + 1);
  };
  const handlePrevCard = () => {
    setCurrentCardIndex(currentCardIndex - 1);
  };

  const datas = useMemo(() => {
    return shuffleArray([...data.quizzes]).map((el) => ({
      question: [el.contentHTML],
      answerQuestion: el.answerOptions.map((answer) => answer.value),
      num: el.num,
    }));
  }, [data.quizzes]);

  const handleRadioButton = (option, index) => {
    setValueRadio((prev) => ({ ...prev, [index]: option }));
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <img src={Images.LOGO} alt="logo" />
        <p>skills.id</p>
      </div>
      <MultipleChoice
        type=""
        numberQuestion={currentCardIndex + 1}
        question={datas[currentCardIndex].question}
        answerQuestion={datas[currentCardIndex].answerQuestion}
        onChange={(e) => handleOptionChange(e.target.value, currentCardIndex)}
        value={selectedOption[currentCardIndex] || ""}
        onNextButtonClick={handleNextCard}
        onPrevButtonClick={handlePrevCard}
        totalQuestion={datas.length}
        onSubmitQuiz={() => handleSubmit(valueRadio)}
        onClickRadio={(item, num) => handleRadioButton(item, num)}
        isSelectedRadio={valueRadio[datas[currentCardIndex].num] || ""}
        totalValueRadio={valueRadio}
        totalValueEssay={selectedOption}
        questionNum={datas[currentCardIndex].num}
        isShuffle
      />
    </div>
  );
}
