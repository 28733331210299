import { API } from "@Config/index";

export function getUserSheet(classId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user-sheet/${classId}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerQuiz(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user-sheet/answer/quiz`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postUkAgreement(userSheetID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/user-sheet/agreement/uk`,
        { userSheetID },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerSurvey(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user-sheet/answer/survey`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerPostPreTest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/user-sheet/answer/post-pre-test`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function postAnswerTpm(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/user-sheet/answer/tpm`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerMatter(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user-sheet/answer/matter`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAnswerUk(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/user-sheet/answer/uk`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postCompletionDate(payload, userSheetId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/user-sheet/completionDate/${userSheetId}`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
