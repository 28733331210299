import { API } from "@Config/index";

export const fetchInstructorClassProgress = (id, progress, limit, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/classProgress/${id}?progress=${progress}&limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchInstructorClassAssesment = (id, type, limit, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/instructor/classAssesment/${id}?type=${type}&limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchStudentAssesment = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/instructor/userSheet/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const _feedbackTpm = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/instructor/feedback/tpm`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const _feedbackUk = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/instructor/feedback/uk`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const _prakerjaSync = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/instructor/sync-user-assignment`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
