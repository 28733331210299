import TpmDetail from "@Organism/InstructorDashboard/TpmDetail";
import { fetchStudentAssesment } from "@Services/instructor";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const { progress } = params;
  const getStudentAssesment = fetchStudentAssesment(progress);
  return defer({
    studentAssesment: getStudentAssesment,
  });
}
export default function TpmDetailPage() {
  const { studentAssesment } = useLoaderData();

  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={studentAssesment}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(value) => <TpmDetail data={value?.data}/>}
      </Await>
    </Suspense>
  );
}
