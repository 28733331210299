import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import { useLocation } from "react-router-dom";

export default function ModalResultScore({
  currentScore = 0,
  isOpen = true,
  handleBack = () => {},
  getScore = 0,
  type = "",
}) {
  const [prevScore, setPrevScore] = useState(0);
  const { pathname } = useLocation();
 
  useEffect(() => {
    setPrevScore(currentScore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const averageScore = type === "kuis" ? 80 : type === "post-test" ? 60 : 80;
  const isLessThan = getScore < averageScore;
  // const isLessThan =
  //   (prevScore !== 0 && getScore < prevScore) ||
  //   (prevScore <= 0 && getScore < averageScore);

  return (
    <Modal isOpen={isOpen}>
      <section className={Styles.containerScore}>
        <h1>Skor Kuis</h1>
        <div className={Styles.score} is-less-than={isLessThan.toString()}>
          <div>
            <span>{getScore}</span>
          </div>
        </div>
        {(prevScore <= 0 && getScore < averageScore) ||
        (getScore < averageScore && prevScore < getScore) ? (
          <p>
            Skor{" "}
            {type === "kuis"
              ? "kuis"
              : type === "post-test"
              ? "post-test"
              : "kuis"}{" "}
            kamu masih dibawah {averageScore}, silahkan kembali dan coba lagi
          </p>
        ) : prevScore > 0 && getScore < prevScore ? (
          <p>
            Skor{" "}
            {type === "kuis"
              ? "kuis"
              : type === "post-test"
              ? "post-test"
              : "kuis"}{" "}
            kamu masih dibawah nilai kamu sebelumnya, silahkan kembali dan coba
            lagi
          </p>
        ) : (
          <p>
            Skor{" "}
            {type === "kuis"
              ? "kuis"
              : type === "post-test"
              ? "post-test"
              : "kuis"}{" "}
            kamu {getScore}, kamu bisa coba lagi atau silahkan untuk melanjutkan
            ke materi selanjutnya
          </p>
        )}
        <div className={Styles.button} onClick={() => handleBack()}>
          <span>Kembali</span>
        </div>
      </section>
    </Modal>
  );
}
