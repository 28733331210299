import Icon from "@Atom/Icon";
import Text from "@Atom/Text";
import Styles from "./style.module.scss";

export default function Button({
  variant = "contained" || "outlined" || "text" || "neutral-contained" || "neutral-outlined" || "neutral-text",
  startIcon,
  endIcon,
  text,
  iconButton,
  disabled,
  textSize = "m",
  textWidth = "medium",
  iconSize = 16,
  className = '',
  onClick = () => { },
  isActive = false,
  dataLength = 0,
  ...props
}) {
  // type = 
  // iconButton = boolean


  return (
    <button
      className={`${Styles.button} ${isActive ? Styles.active : ''} ${className}`}
      variant={variant}
      iconbutton={iconButton ? "true" : "false"}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {startIcon && <Icon size={iconSize} className={Styles.icon} icon={startIcon} />}
      {text && (
        <Text size={textSize} width={textWidth}>
          {text}
        </Text>
      )}
      {variant === 'table-tab' && <div className={Styles.label}>{dataLength}</div>}
      {endIcon && <Icon size={iconSize} className={Styles.icon} icon={endIcon} />}
    </button>
  );
}
