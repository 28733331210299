import React from "react";
import Styles from "./style.module.scss";
import loading from "@Assets/animation/loading.json";
import Lottie from "lottie-react";
import Button from "@Atom/Button";
import Images from "@Theme/Images";

export default function PrakerjaSyncModal({
  status = 0,
  handleClose,
  message,
}) {
  switch (status) {
    case 0:
      return (
        <div className={Styles.container}>
          <p>Sinkronisasi Berhasil</p>
          <div className={Styles.modalBody}>
            <img src={Images.CHECKLIST} alt="" />
            <p>
              {`Proses sinkronisasi telah selesai silahkan lanjutkan
              kamu dapat melanjutkan proses penilaian TPM`}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button variant="outlined" text={"Tutup"} onClick={handleClose} />
          </div>
        </div>
      );
    case 1:
      return (
        <div className={Styles.container}>
          <p>Sinkronisasi Gagal</p>
          <div className={Styles.modalBody}>
            <img src={Images.REJECTED} alt="" />
            <p>
              {message
                ? message
                : `Proses sinkronisasi gagal, silahkan sinkronisasi secara
              manual satu persatu setiap TPM.`}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button variant="outlined" text={"Tutup"} onClick={handleClose} />
          </div>
        </div>
      );
    default:
      return (
        <div className={Styles.container}>
          <p>Proses Sinkronisasi Sedang Berlangsung</p>
          <div className={Styles.modalBody}>
            <Lottie animationData={loading} loop={true} />
            <p>
              {`Harap tunggu di halaman ini hingga proses 
              sinkronisasi tugas praktek mandiri selesai`}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button
              variant="outlined"
              text={"Tutup"}
              onClick={handleClose}
              disabled={true}
            />
          </div>
        </div>
      );
  }
}
