import Button from "@Atom/Button";
import React from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";

export default function NextPrevButton({
  noNext,
  noPrev,
  nextText,
  prevText,
  handleNext,
  handlePrev,
  disabledNext,
  disabledPrev,
}) {
  const { width } = useWindowSize();

  if (width <= 768) {
    return (
      <div className={`${Styles.containerMobile}`}>
        {!noNext && (
          <Button
            variant="outlined"
            text={nextText}
            disabled={disabledNext}
            onClick={handleNext}
            endIcon={"chevron-right"}
          />
        )}
        {!noPrev && (
          <Button
            variant="outlined"
            text={prevText}
            disabled={disabledPrev}
            onClick={handlePrev}
            startIcon={"chevron-left"}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${Styles.container} ${
          noPrev || noNext ? Styles.single : Styles.multiple
        }`}
      >
        {!noPrev && (
          <Button
            variant="text"
            text={prevText}
            disabled={disabledPrev}
            onClick={handlePrev}
            startIcon={"chevron-left"}
          />
        )}
        {!noNext && (
          <Button
            variant="text"
            text={nextText}
            disabled={disabledNext}
            onClick={handleNext}
            endIcon={"chevron-right"}
          />
        )}
      </div>
    );
  }
}
