import Card from '@Atom/Card'
import Modal from '@Atom/Modal'
import ModalHeader from '@Atom/ModalHeader'
import Range from '@Atom/Range'
import Images from '@Theme/Images'
import { useMemo, useState } from 'react'
import Styles from './style.module.scss'
import Button from '@Atom/Button'

export default function PhotoPositioningModal({
  file,
  onSave = () => { },
  onClose = () => { },
}) {

  const [scaleRange, setScaleRange] = useState(0)

  const scale = useMemo(() => {
    return 1 + (+scaleRange / 100)
  }, [scaleRange])

  return (
    <Modal isOpen={file ? true : false}>
      <Card className={Styles.card}>
        <ModalHeader
          title='Tambah Foto Profil'
          withCloseOption
          onClose={onClose}
        />
        <div className={Styles.imgBox}>
          <img style={{
            transform: `scale(${scale}, ${scale})`
          }} className={Styles.img} src={file ? URL.createObjectURL(file) : ''} alt='' />
          <img className={Styles.overlay} src={Images.PROFILE_OVERLAY} alt='' />
        </div>
        <Range
          value={scaleRange}
          setValue={setScaleRange}
          min={0}
          max={100}
          step={2}
        />
        <div className={Styles.actions}>
          <Button
            text={'Kembali'}
            onClick={onClose}
            variant='outlined'
          />
          <Button
            text={'Simpan'}
            onClick={onSave}
          />
        </div>
      </Card>
    </Modal>
  )
}