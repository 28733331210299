import LearnClass from "@Organism/LearnClass";
import { getCourseForLearn } from "@Services/userCourse";
import { getUserSheet } from "@Services/userSheet";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const id = params.id;

  const getClassData = await getCourseForLearn(id);
  const getProgress = await getUserSheet(id);

  return defer({
    classData: getClassData,
    userSheet: getProgress,
  });
}

const LearnClassPage = () => {
  const { classData, userSheet } = useLoaderData();

  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={classData}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {(dataClass) => (
          <Suspense>
            <Await resolve={userSheet}>
              {(userSheet) => (
                <LearnClass data={dataClass} userSheet={userSheet} />
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
  // return <LearnClass />;
};

export default LearnClassPage;
