import { decryptStorageData } from "@Helpers/encryptDecrypt";
import AuthLayout from "@Organism/Auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthPage = ({ type }) => {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      if (user.role === "TEACHER") {
        navigate("/instructor");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <AuthLayout type={type} />;
};

export default AuthPage;
