import ClassesSidebar from "@Molecule/ClassesSidebar";
import React from "react";
import { Outlet } from "react-router-dom";

export default function VideoClass({ data, userSheet }) {
  return (
    <div style={{ display: "flex" }}>
      <ClassesSidebar userSheet={userSheet} data={data.data} />
      <div style={{ width: "100%" }}>
        <Outlet />
      </div>
    </div>
  );
}
