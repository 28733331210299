import React, { useRef } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import FileIcon from '@Assets/Icon/file_icon.svg'

export default function UploadFileSection({
  file,
  handleChangeFile,
  fileName,
  handleEdit = true,
  withLabel = true,
  ...props
}) {
  const inputRef = useRef();
  return (
    <div className={Styles.container}>
      {withLabel && (
        <p className={Styles.label}>
          Upload File <span>*</span>
        </p>
      )}
      <input
        className={Styles.fileInput}
        id={"input-tpm"}
        type={"file"}
        hidden
        onChange={(e) => {
          handleChangeFile(e);
        }}
        ref={inputRef}
        {...props}
        maxSize="1024"
        // max
      />
      {file ? (
        <div className={Styles.fileWrapper}>
          <img src={FileIcon} alt="" />
          <p className={Styles.fileText}>
            {fileName.split("-").slice(1).join("-")}
          </p>
          {handleEdit && (
            <label htmlFor="input-tpm">
              <Icon icon={"edit"} size={22} color={"#A87EB9"} />
            </label>
          )}
        </div>
      ) : (
        <div className={Styles.fileWrapper}>
          <Icon icon={"cloud-upload"} size={20} color={"#9360A8"} />
          <p className={Styles.placeholder}>
            Pilih file untuk hasil uji keterampilan
          </p>
          <Button
            text={"Choose File"}
            variant="outlined"
            onClick={() => {
              inputRef?.current?.click();
            }}
          />
        </div>
      )}
    </div>
  );
}
